import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">
            
<path d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z" />
<path d="M118.734 16.0851L121.439 16.9333C122.994 17.4169 123.732 18.2969 123.296 19.6762L123.288 19.7159C123.05 20.469 122.526 20.9526 121.59 20.9446C122.55 21.4916 122.86 22.1972 122.542 23.204L122.526 23.2436C122.082 24.6468 120.932 25.067 119.273 24.5437L116.361 23.6321L118.734 16.0851ZM119.416 23.4973C120.36 23.7906 120.924 23.5052 121.17 22.7204L121.185 22.6808C121.431 21.896 121.122 21.3727 120.083 21.0477L118.813 20.6513L118.051 23.0692L119.416 23.4973ZM120.273 20.0647C121.201 20.358 121.669 20.1281 121.915 19.3591L121.931 19.3195C122.153 18.5981 121.844 18.1621 120.939 17.8846L119.797 17.5279L119.115 19.708L120.273 20.0647Z" />
<path d="M128.747 19.6287L130.517 20.3976L129.747 28.6977L128.343 28.0873L128.549 26.042L125.772 24.837L124.415 26.3829L123.146 25.828L128.747 19.6287ZM126.518 23.9809L128.66 24.9163L129.065 21.0794L126.518 23.9809Z" />
<path d="M135.412 22.7363L136.935 23.6242V30.2516L139.823 25.3048L140.982 25.9787L136.991 32.8122L135.666 32.0432L135.642 25.0036L132.587 30.2437L131.429 29.5698L135.412 22.7363Z" />
<path d="M143.799 27.8495L145.727 29.2923C147.94 30.9491 148.067 33.0103 146.687 34.8495L146.623 34.9288C145.243 36.7759 143.18 37.2832 140.958 35.6185L139.046 34.1915L143.799 27.8495ZM141.617 34.6592C143.037 35.7215 144.33 35.4203 145.385 34.0171L145.441 33.9457C146.465 32.5822 146.465 31.29 144.973 30.1722L144.291 29.6649L140.934 34.1439L141.617 34.6592Z" />
<path d="M148.241 36.3318L148.305 36.2684C149.947 34.5402 152.28 34.4609 153.883 35.9751C155.2 37.2276 155.605 38.8528 154.446 40.3273L153.359 39.2967C153.962 38.4168 153.907 37.6161 153.081 36.8392C152.066 35.88 150.661 36.0385 149.44 37.3307L149.376 37.3941C148.146 38.6863 148.043 40.0419 149.106 41.0487C149.9 41.8018 150.852 41.9366 151.733 41.231L152.78 42.222C151.296 43.4428 149.654 43.2129 148.281 41.9207C146.48 40.2084 146.567 38.0997 148.241 36.3318Z" />
<path d="M159.445 42.0001L160.691 43.4746L156.319 50.5777L155.335 49.4123L156.43 47.6683L154.478 45.3535L152.582 46.1462L151.693 45.0919L159.445 42.0001ZM155.518 44.9174L157.025 46.7011L159.088 43.4429L155.518 44.9174Z" />
<path d="M164.095 47.7396L165.166 49.3965L161.754 54.0816L167.403 52.8529L168.466 54.4939L161.809 58.7985L161.008 57.5618L166.039 54.3115L159.723 55.5799L159.461 55.1836L163.182 49.9118L158.152 53.162L157.43 52.0443L164.095 47.7396Z" />
<path d="M170.069 57.4984L171.188 59.7815C171.966 61.375 171.775 62.7623 170.284 63.4916L170.244 63.5154C168.744 64.2527 167.538 63.4916 166.793 61.9695L166.301 60.9786L163.595 62.3025L162.944 60.9786L170.069 57.4984ZM167.737 61.478C168.165 62.3501 168.824 62.6117 169.609 62.2232L169.649 62.2074C170.498 61.7951 170.577 61.1371 170.165 60.2889L169.689 59.3217L167.261 60.5109L167.737 61.478Z" />
<path d="M170.284 70.0951L174.973 68.6444L175.409 70.0634L170.823 71.4825C169.435 71.9105 168.998 72.5368 169.347 73.6546C169.688 74.7486 170.339 75.145 171.91 74.6614L176.401 73.2741L176.837 74.6931L172.227 76.1201C170.244 76.7305 168.824 76.0011 168.205 74.0034C167.633 72.0929 168.332 70.6976 170.284 70.0951Z" />
<path d="M172.156 78.435L172.426 79.8302C171.664 80.0839 171.069 80.5199 171.323 81.8359C171.489 82.6921 172.085 83.2153 172.807 83.0726C173.529 82.9378 173.727 82.5256 173.687 81.2175C173.576 79.307 173.973 78.3161 175.433 78.0386C176.702 77.7929 177.877 78.6966 178.186 80.3138C178.511 81.9865 177.94 83.136 176.48 83.5799L176.226 82.2561C177.012 81.9548 177.274 81.4237 177.099 80.5279C176.925 79.6479 176.417 79.2753 175.814 79.3863C175.179 79.5052 174.917 79.8302 174.981 81.2175C175.084 83.0964 174.743 84.1428 173.187 84.444C171.839 84.7056 170.561 83.7702 170.228 82.0499C169.823 79.9254 170.712 78.8314 172.156 78.435Z" />
<path d="M179.067 86.3149L179.519 91.2459L178.36 91.3489L178.043 87.8846L175.877 88.0828L176.131 90.8653L175.028 90.9684L174.774 88.1858L172.465 88.3999L172.798 92.0545L171.64 92.1575L171.172 87.0363L179.067 86.3149Z" />
<path d="M179.669 94.3059L179.63 96.9616C179.598 98.7057 178.868 99.9107 177.249 99.8789H177.202C175.956 99.8552 175.337 99.0703 175.099 98.0794L171.632 100.252L171.656 98.6581L174.893 96.6366L174.909 95.6932L171.703 95.6377L171.727 94.1632L179.669 94.3059ZM175.956 96.9061C175.94 97.8891 176.353 98.4203 177.194 98.4361H177.241C178.154 98.452 178.471 97.8891 178.487 96.9458L178.503 95.7566L175.972 95.717L175.956 96.9061Z" />
<path d="M178.988 106.966L178.226 111.857L177.076 111.675L177.615 108.234L175.465 107.902L175.037 110.66L173.942 110.486L174.37 107.727L172.077 107.37L171.514 110.993L170.363 110.811L171.157 105.729L178.988 106.966Z" />
<path d="M173.355 115.837L177.695 114.537L177.267 116.146L174.117 117.05L176.41 119.389L176.021 120.855L172.926 117.613L168.309 119.016L168.737 117.391L172.204 116.384L169.689 113.792L170.078 112.333L173.355 115.837Z" />
<path d="M175.053 123.725L174.14 126.103C173.505 127.76 172.355 128.569 170.8 127.974L170.76 127.958C169.197 127.364 168.935 125.968 169.538 124.383L169.935 123.344L167.118 122.266L167.642 120.887L175.053 123.725ZM170.546 124.732C170.197 125.643 170.459 126.301 171.276 126.611L171.315 126.626C172.196 126.959 172.736 126.579 173.069 125.699L173.45 124.692L170.919 123.725L170.546 124.732Z" />
<path d="M171.934 131.375L169.721 135.806L168.681 135.283L170.236 132.176L168.292 131.208L167.047 133.706L166.055 133.214L167.301 130.717L165.23 129.686L163.587 132.968L162.548 132.445L164.849 127.847L171.934 131.375Z" />
<path d="M168.236 138.454L166.816 140.698C165.887 142.164 164.634 142.814 163.261 141.95L163.221 141.926C162.174 141.261 162.055 140.27 162.365 139.302L158.278 139.35L159.135 138.002L162.952 137.963L163.459 137.17L160.754 135.458L161.539 134.213L168.236 138.454ZM163.705 138.74C163.174 139.572 163.253 140.238 163.967 140.69L164.007 140.714C164.777 141.197 165.34 140.888 165.848 140.095L166.482 139.096L164.348 137.741L163.705 138.74Z" />
<path d="M163.381 145.732L162.484 146.921L156.145 142.156L157.041 140.967L163.381 145.732Z" />
<path d="M160.46 149.497L157.183 153.215L156.31 152.446L158.611 149.83L156.977 148.395L155.128 150.496L154.295 149.767L156.144 147.666L154.406 146.136L151.978 148.887L151.105 148.118L154.501 144.257L160.46 149.497Z" />
<path d="M155.058 155.435L153.764 156.624L147.425 154.666L151.305 158.883L150.313 159.787L144.949 153.96L146.076 152.922L152.82 154.975L148.71 150.504L149.694 149.6L155.058 155.435Z" />
<path d="M145.647 158.455L145.703 158.527C147.099 160.453 146.742 162.768 144.957 164.06C143.481 165.122 141.807 165.217 140.577 163.814L141.791 162.934C142.545 163.687 143.339 163.783 144.267 163.117C145.402 162.3 145.497 160.889 144.457 159.446L144.402 159.375C143.354 157.932 142.045 157.575 140.863 158.432C139.974 159.074 139.673 159.977 140.205 160.976L139.038 161.824C138.11 160.144 138.641 158.574 140.165 157.464C142.164 156.014 144.219 156.481 145.647 158.455Z" />
<path d="M139.649 167.461L135.348 169.918L134.769 168.912L137.792 167.183L136.713 165.297L134.285 166.684L133.737 165.725L136.165 164.337L135.015 162.324L131.825 164.147L131.246 163.14L135.713 160.588L139.649 167.461Z" />
<path d="M129.017 172.963L126.772 173.827C124.193 174.818 122.376 173.827 121.551 171.678L121.519 171.583C120.694 169.435 121.36 167.421 123.955 166.422L126.185 165.566L129.017 172.963ZM124.407 167.485C122.749 168.119 122.321 169.379 122.947 171.012L122.979 171.092C123.59 172.685 124.685 173.367 126.423 172.701L127.216 172.4L125.209 167.176L124.407 167.485Z" />
<path d="M120.115 176.142L115.322 177.418L115.021 176.292L118.385 175.396L117.822 173.296L115.124 174.017L114.838 172.947L117.536 172.225L116.941 169.99L113.394 170.933L113.093 169.808L118.068 168.484L120.115 176.142Z" />
<path d="M111.958 172.59L110.554 172.82C110.324 172.051 109.903 171.441 108.578 171.663C107.713 171.805 107.182 172.384 107.293 173.105C107.412 173.827 107.816 174.041 109.118 174.041C111.03 173.985 112.006 174.413 112.252 175.88C112.458 177.156 111.522 178.298 109.895 178.567C108.213 178.845 107.078 178.242 106.682 176.768L108.015 176.554C108.292 177.355 108.816 177.624 109.713 177.481C110.601 177.339 110.99 176.839 110.895 176.237C110.792 175.603 110.474 175.325 109.086 175.349C107.205 175.396 106.166 175.024 105.912 173.462C105.69 172.107 106.666 170.862 108.396 170.577C110.538 170.212 111.609 171.131 111.958 172.59Z" />
<path d="M103.913 179.217L102.429 179.336L101.786 171.441L103.27 171.322L103.913 179.217Z" />
<path d="M99.5323 175.602V175.69C99.4926 178.052 97.8264 179.709 95.5096 179.669C93.8116 179.638 92.3199 178.845 92.1374 176.942L93.6132 176.966C93.7719 178.02 94.4781 178.488 95.5096 178.504C97.0647 178.528 97.9772 177.394 98.0089 175.65V175.563C98.0406 173.866 97.271 172.693 95.6048 172.661C94.1211 172.638 93.4942 173.525 93.4308 174.619L95.4302 174.651L95.4144 175.761L91.9629 175.706L91.9788 174.976C92.0105 172.78 93.4149 171.472 95.6524 171.512C98.1755 171.559 99.564 173.264 99.5323 175.602Z" />
<path d="M88.9167 179.297L87.1632 179.075L84.5687 172.971L83.8467 178.655L82.5137 178.488L83.5055 170.624L85.0209 170.814L87.798 177.291L88.5597 171.266L89.8927 171.433L88.9167 179.297Z" />
<path d="M79.2128 177.83L74.3887 176.681L74.6584 175.547L78.0464 176.356L78.5463 174.247L75.8248 173.597L76.0787 172.519L78.8002 173.169L79.3397 170.917L75.7693 170.069L76.039 168.935L81.0456 170.125L79.2128 177.83Z" />
<path d="M71.4843 175.84L68.9691 174.968C67.3267 174.398 66.4063 173.327 66.9379 171.797L66.9538 171.758C67.3584 170.584 68.3026 170.243 69.3182 170.331L68.3423 166.367L69.8498 166.89L70.7543 170.592L71.643 170.901L72.6983 167.881L74.0868 168.365L71.4843 175.84ZM70.1751 171.504C69.2468 171.179 68.6121 171.409 68.3344 172.202L68.3185 172.241C68.017 173.097 68.4534 173.581 69.342 173.89L70.4608 174.279L71.2939 171.892L70.1751 171.504Z" />
<path d="M62.129 167.247C61.7799 167.937 60.9468 168.238 60.2248 167.873C59.4948 167.501 59.2488 166.66 59.5979 165.971C59.955 165.265 60.7722 164.972 61.5022 165.344C62.2242 165.709 62.4861 166.541 62.129 167.247Z" />
<path d="M56.4337 160.429L55.7989 161.388C55.6323 161.143 55.4498 160.921 55.061 160.659C54.458 160.263 53.8629 160.318 53.3234 161.135L50.2925 165.709L49.0547 164.892L52.1015 160.287C53.0457 158.852 54.3073 158.741 55.5292 159.549C56.0687 159.914 56.2909 160.176 56.4337 160.429Z" />
<path d="M49.5303 159.248L46.4439 163.061L45.2854 162.126L48.3084 158.4C49.2209 157.274 49.2368 156.513 48.3243 155.776C47.4357 155.054 46.666 155.094 45.6346 156.37L42.675 160.025L41.5166 159.089L44.5475 155.34C45.8488 153.73 47.4436 153.548 49.0702 154.864C50.6174 156.109 50.8157 157.67 49.5303 159.248Z" />
<path d="M43.3179 153.366L42.3181 152.359C42.8101 151.725 43.056 151.027 42.1119 150.076C41.493 149.457 40.7075 149.362 40.1917 149.878C39.676 150.393 39.7315 150.845 40.4932 151.907C41.6517 153.429 41.8738 154.468 40.8265 155.522C39.9061 156.434 38.4303 156.339 37.2719 155.165C36.0658 153.952 35.8992 152.684 36.8672 151.503L37.8193 152.462C37.3274 153.152 37.4147 153.738 38.0574 154.38C38.6921 155.015 39.3269 155.038 39.7553 154.61C40.2155 154.158 40.2473 153.738 39.4221 152.628C38.2875 151.13 37.986 150.076 39.1126 148.958C40.0886 147.991 41.6675 148.054 42.9053 149.299C44.4208 150.829 44.3017 152.232 43.3179 153.366Z" />
<path d="M33.2403 148.895L34.6367 150.599L33.7402 151.336L29.9951 146.778L30.8917 146.041L32.2882 147.745L37.5169 143.456L38.4611 144.606L33.2403 148.895Z" />
<path d="M28.4564 144.606L27.6074 143.393L34.0978 138.851L34.9467 140.063L28.4564 144.606Z" />
<path d="M25.7272 140.65L24.8465 139.128L28.1393 133.381L23.1723 136.242L22.5059 135.077L29.3691 131.113L30.1308 132.437L26.6556 138.565L31.9161 135.529L32.5905 136.686L25.7272 140.65Z" />
<path d="M19.3948 128.434L18.4506 125.953C17.8317 124.327 18.0618 122.94 19.5773 122.361L19.617 122.346C20.7754 121.902 21.6402 122.409 22.2353 123.233L24.6474 119.936L25.2107 121.426L22.9494 124.502L23.2827 125.382L26.2739 124.248L26.7976 125.628L19.3948 128.434ZM21.8783 124.644C21.5291 123.725 20.9499 123.384 20.1644 123.685L20.1248 123.701C19.2758 124.026 19.1885 124.66 19.5217 125.54L19.9423 126.65L22.3067 125.754L21.8783 124.644Z" />
<path d="M16.4037 120.11L16.0387 118.683L19.2997 117.851L18.419 114.41L15.158 115.242L14.793 113.807L22.4735 111.849L22.8384 113.284L19.5774 114.117L20.4581 117.557L23.7192 116.725L24.0842 118.152L16.4037 120.11Z" />
<path d="M18.158 110.343L18.0707 110.351C15.7697 110.644 13.8575 109.21 13.5639 106.879C13.2704 104.532 14.7224 102.709 17.0392 102.416L17.1265 102.408C19.4275 102.114 21.3555 103.47 21.657 105.856C21.9585 108.266 20.4272 110.058 18.158 110.343ZM17.3248 103.93L17.2376 103.938C15.6189 104.144 14.5319 105.198 14.7303 106.736C14.9287 108.274 16.1744 109.043 17.8723 108.829L17.9596 108.821C19.6734 108.607 20.6573 107.418 20.4748 105.991C20.2844 104.501 19.0307 103.716 17.3248 103.93Z" />
<path d="M13.0317 98.3409L13 96.4146L20.8868 93.6716L20.9106 95.2016L18.9587 95.8676L19.0063 98.8959L20.982 99.4825L21.0058 100.87L13.0317 98.3409ZM17.9273 98.5629L17.8876 96.2243L14.2219 97.4451L17.9273 98.5629Z" />
<path d="M13.127 91.0081L13.365 88.614C13.6427 85.871 15.3407 84.6898 17.6337 84.9197L17.7368 84.9277C20.0299 85.1576 21.5295 86.6559 21.2518 89.4226L21.0137 91.7929L13.127 91.0081ZM20.0854 89.3512C20.26 87.5913 19.3317 86.64 17.5861 86.4656L17.4988 86.4577C15.8008 86.2912 14.698 86.9571 14.5155 88.8042L14.4282 89.6604L19.9981 90.2153L20.0854 89.3512Z" />
<path d="M14.2695 81.6376L15.2772 76.786L16.4198 77.0238L15.7136 80.4327L17.84 80.8766L18.4034 78.1416L19.4904 78.3636L18.927 81.0907L21.1963 81.5584L21.9421 77.9672L23.0846 78.205L22.0373 83.239L14.2695 81.6376Z" />
<path d="M21.1087 75.9456L21.5371 74.59C22.3306 74.7327 23.0606 74.6297 23.4652 73.3454C23.727 72.513 23.4493 71.7758 22.7511 71.5538C22.0529 71.3318 21.68 71.6014 21.0928 72.7667C20.2835 74.4949 19.4663 75.1846 18.046 74.7407C16.8082 74.3522 16.2052 73.0045 16.7051 71.4269C17.2129 69.8018 18.2602 69.0566 19.7598 69.3658L19.3473 70.658C18.5141 70.547 18.0301 70.8958 17.7604 71.7599C17.4906 72.6161 17.7683 73.1869 18.3475 73.3692C18.9585 73.5595 19.3473 73.4009 19.9503 72.1563C20.7516 70.4598 21.5451 69.6988 23.0606 70.1665C24.3697 70.5787 25.0521 72.0057 24.5284 73.6784C23.8699 75.7395 22.5766 76.2865 21.1087 75.9456Z" />
<path d="M24.2669 64.4587C23.5608 64.1337 23.2355 63.3092 23.5767 62.572C23.9258 61.8268 24.7589 61.5572 25.465 61.8823C26.1791 62.2152 26.4965 63.0238 26.1553 63.769C25.8142 64.5063 24.981 64.7917 24.2669 64.4587Z" />
<path d="M30.9085 58.5369L29.9246 57.9344C30.1626 57.76 30.3848 57.5697 30.6307 57.1734C31.0116 56.5629 30.9323 55.9684 30.0991 55.4531L25.4258 52.5754L26.2034 51.307L30.9085 54.2085C32.3684 55.1122 32.5271 56.3648 31.7574 57.6094C31.4163 58.1564 31.1624 58.3942 30.9085 58.5369Z" />
<path d="M29.0986 47.1372L30.2571 45.5992L38.1677 48.2708L37.2473 49.4917L35.2954 48.8178L33.4784 51.2357L34.6765 52.9084L33.8434 54.0183L29.0986 47.1372ZM32.8278 50.3082L34.2322 48.4373L30.5903 47.161L32.8278 50.3082Z" />
<path d="M33.6445 41.3896L34.8585 40.1133L41.3171 41.6354L37.1515 37.6954L38.0719 36.7203L43.8323 42.1665L42.777 43.2764L35.9058 41.6909L40.3174 45.8687L39.397 46.8438L33.6445 41.3896Z" />
<path d="M43.6666 38.2028L40.5166 34.4372L41.6592 33.4859L44.7377 37.1722C45.666 38.29 46.4119 38.4485 47.3084 37.6954C48.1892 36.9661 48.3002 36.205 47.2529 34.9446L44.2378 31.3375L45.3804 30.3862L48.4748 34.0884C49.7998 35.6818 49.6729 37.2752 48.0702 38.615C46.5388 39.8834 44.9678 39.7724 43.6666 38.2028Z" />
<path d="M49.4746 27.4768L51.0853 26.4066L57.655 31.5595L56.3775 32.4077L54.7669 31.1235L52.2437 32.7962L52.815 34.7701L51.6566 35.5391L49.4746 27.4768ZM51.9263 31.7101L53.8782 30.4179L50.8711 28L51.9263 31.7101Z" />
<path d="M55.6553 23.6083L58.0197 22.3874C59.5669 21.5867 60.9713 21.6581 61.7172 23.093L61.741 23.1326C62.3122 24.2345 61.9155 25.1541 61.1538 25.828L64.7084 27.8416L63.2882 28.5788L59.9716 26.6921L59.1385 27.1281L60.6063 29.9661L59.2972 30.64L55.6553 23.6083ZM59.7018 25.6456C60.5746 25.1938 60.8444 24.5833 60.4635 23.8302L60.4397 23.7906C60.0192 22.982 59.3765 22.9661 58.5434 23.4021L57.4881 23.9491L58.6465 26.1926L59.7018 25.6456Z" />
<path d="M66.7162 23.3149L62.6221 20.1043L64.1375 19.5018L67.0098 21.8959L67.4224 18.2017L68.7633 17.6705L68.1047 22.7679L69.3663 25.9548L67.9857 26.5018L66.7162 23.3149Z" />
<path d="M76.0466 23.4179L78.2444 19.6603C78.7998 18.709 79.0775 17.9321 78.9109 17.1948C78.7443 16.4734 78.2285 16.1167 77.4827 16.2911C76.7131 16.4734 76.3322 17.0284 76.4592 18.1461L75.1421 18.4553C74.8406 16.7509 75.634 15.538 77.2606 15.1575C78.8554 14.7849 80.0217 15.4746 80.3391 16.8381C80.5771 17.8449 80.1883 18.8517 79.4743 19.9536L78.1492 22.0306L81.434 21.2617L81.6959 22.3715L76.1101 23.6795L76.0466 23.4179Z" />
<path d="M82.9183 18.3047L82.8707 17.9083C82.5692 15.5222 83.6959 13.8891 85.6795 13.6354C87.671 13.3817 89.1547 14.666 89.4563 17.0284L89.5039 17.4248C89.8054 19.7872 88.766 21.4519 86.711 21.7135C84.7035 21.9752 83.2198 20.6671 82.9183 18.3047ZM88.0439 17.6229L87.9963 17.2345C87.7821 15.5618 87.0125 14.6343 85.8382 14.7849C84.648 14.9355 84.1243 15.9661 84.3465 17.7101L84.3941 18.1065C84.6242 19.8902 85.4176 20.6988 86.5999 20.5482C87.8059 20.3976 88.2661 19.3987 88.0439 17.6229Z" />
<path d="M91.4238 20.8336L94.3437 17.6071C95.0816 16.7906 95.518 16.085 95.5021 15.3319C95.4862 14.5867 95.0498 14.1349 94.2881 14.1507C93.5026 14.1666 93.0107 14.6343 92.9076 15.76L91.5508 15.7917C91.6063 14.0635 92.6299 13.0409 94.304 13.0012C95.9464 12.9616 96.9462 13.8812 96.9779 15.2764C97.0017 16.307 96.4146 17.2187 95.4942 18.1541L93.7724 19.914L97.1445 19.8348L97.1683 20.9763L91.4318 21.1111L91.4238 20.8336Z" />
<path d="M100.873 14.6739L99.1357 15.7124L99.2072 14.4202L101.119 13.2231L102.436 13.3024L101.976 21.2062L100.493 21.119L100.873 14.6739Z" />
<path d="M108.593 18.5664C108.728 17.8053 109.442 17.2742 110.236 17.4169C111.045 17.5596 111.521 18.2969 111.386 19.0579C111.252 19.8348 110.553 20.3501 109.744 20.2074C108.951 20.0647 108.459 19.3354 108.593 18.5664Z" />


            </svg>
        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;




